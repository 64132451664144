// ---------
// MEDIA_QUERIES
// ---------

$breakpoint-large: 72rem; // 1152px
$breakpoint-medium: 60rem; // 960px
$breakpoint-small: 48rem; // 768px
$breakpoint-extra-small: 40rem; // 640px

$gl-gutter: 1.5rem; //24px
$gl-gutter-vertical: 1.5rem; //24px
$gl-mq-list: (
  lg: $breakpoint-large,
  md: $breakpoint-medium,
  sm: $breakpoint-small,
  xs: $breakpoint-extra-small,
);

// ---------
// COLORS
// ---------
$navbar-color: white;

// ---------
// ANIMATIONS
// ---------
$ease-in-out: cubic-bezier(0.24, 0.06, 0.08, 1);
$long-ease: 1s cubic-bezier(0, 1, 0, 1);

// ---------
// MISCELLANEOUS
// ---------
$banner-height: 200px;
$navbar-height: 52px;

$grey-120: rgba(25, 29, 36, 0.87);
$grey-110: #050505;
$grey-90: #626262;
$grey-70: #a3a3a3;
$grey-60: #ebebeb;
$grey-50: #c8c8c8;
$grey-20: rgba(25, 29, 36, 0.12);
$grey-10: #f9f9f9;

$blue-60: #2b627b;
$blue-20: rgb(242, 246, 247);
$blue-10: #eaeff2;

$orange-60: #d93914;
$orange-50: #f4511e;
$orange-10: #fbebe7;
$orange-5: #fbf5f3;
