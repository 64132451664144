* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: subpixel-antialiased;
}

p {
  @apply leading-chillax m-0;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

ul {
  margin: 0;
  padding: 0;
}

input::-ms-clear {
  display: none;
}

h1,
h2,
h3,
h4,
h6 {
  color: rgba(5, 5, 5, 1);
}

h5 {
  color: rgba(49, 49, 49, 1);
}

textarea:focus,
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.loading-content {
  opacity: 0.5;
  transition: 0.2s $ease-in-out;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .full-height-container {
    height: 100px;
  }
}

// Shell content
.shell-content {
  margin-left: 0px;
  min-height: 100vh;
  transition: $long-ease;
  padding-top: $banner-height + $navbar-height;

  &.menu-opened {
    margin-left: 267px;
  }
}

.shell-content.hide-banner {
  padding-top: $navbar-height;
  .fixed-desktop-header {
    top: -$banner-height;
  }
}

// Card
.card {
  display: block;
  @apply bg-white rounded-[10px] shadow-lg;
  &.raised {
    transition: 0.3s $ease-in-out;
    cursor: pointer;
    &:hover {
      @apply shadow-xl;
    }
  }
}
.card-header {
  @apply font-semibold text-lg text-grey-9;
  line-height: 1;
}

.text-display {
  letter-spacing: 0.5px;
  @apply font-semibold uppercase;
}
.text-highlight {
  @apply bg-pulse-10/10;
}

.text-title {
  @apply font-semibold text-grey-9;
}
.text-header {
  letter-spacing: 1px;
  @apply font-semibold text-xs text-grey-6 uppercase;
}
.text-note {
  @apply text-grey-6 italic;
}

.blx-container {
  margin-right: auto !important;
  margin-left: auto !important;
  padding-right: 2vw;
  padding-left: 16px;
  float: none !important;
}

.blx-container.small {
  max-width: calc(#{$breakpoint-medium} + 4vw);
}
.blx-container.extra-small {
  max-width: calc(#{$breakpoint-small} + 40px);
  @apply pl-5 pr-5;
}
@media (min-width: $breakpoint-medium) {
  .blx-container.extra-small-lg {
    max-width: calc(#{$breakpoint-small} + 40px);
    @apply pl-5 pr-5;
  }
}

.hr {
  @apply border-b border-grey-3;
}

.hr-left {
  @apply border-l border-grey-3;
}

.hr-top {
  @apply border-t border-grey-3;
}
.dotted-hr {
  height: 1px;
  background-image: linear-gradient(
    to right,
    #ced4da 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: right;
  background-size: 0.5rem 0.125rem;
  background-repeat: repeat-x;
}

li {
  @apply list-disc;
}

.markdown {
  h4 {
    @apply text-grey-8 mb-2.5;
  }
  h4:not(:first-child) {
    @apply mt-6;
  }
  ul,
  ol {
    @apply mb-2.5 mt-2.5 pl-5;
  }
  li {
    @apply leading-relaxed;
  }
  li + li,
  p + p {
    @apply mt-2.5;
  }

  ol + ul {
    @apply list-disc;
  }

  ol,
  ul {
    @apply list-disc;
  }

  blockquote {
    box-shadow: inset 2px 0 0 #ced4da;
    @apply bg-grey-1 text-grey-8 py-1.25 px-5 my-2.5 mx-0;
    font-style: italic;
  }
}

.sort {
  @apply text-grey-7 font-semibold;
  user-select: none;
  span {
    @apply text-grey-6;
    cursor: pointer;
    transition: 0.1s;
  }
  span:hover,
  .selected {
    @apply text-grey-10;
  }
  i {
    opacity: 0;
  }
  .selected i {
    opacity: 1;
  }
}

.clear-btn {
  border-radius: 50%;
  transition: 0.2s;
  @apply text-base p-1.25 cursor-pointer;
  &:hover {
    @apply bg-grey-3;
  }
}

// <i class="ml-2 material-icons remove-btn" (click)="doSomething()">clear</i>
.remove-btn {
  vertical-align: middle;
  cursor: pointer;
  @apply text-base;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}

.new {
  position: relative;
  &::before {
    content: 'new';
    background: #e74f31;
    border-radius: 3px;
    color: white;
    font-size: 9px;
    text-transform: uppercase;
    padding: 2px 3px 1px;
    letter-spacing: 1px;
    text-shadow: 0 0 1px #630000;
    line-height: normal;
  }
  &.module-icon::before {
    position: absolute;
    bottom: -5px;
    right: -5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  }
  &.nav-item::before {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
    display: inline-block;
  }
}

.carret {
  transform: translate(-3px, 7px);
}

.scrollable-y {
  overflow-y: auto;
  overflow-x: hidden;
}

@media print {
  .hide-in-print,
  .hide-in-print * {
    display: none !important;
  }
}

.fixed-sidenav {
  position: fixed;
  top: calc(#{$banner-height} + #{$navbar-height});
  height: calc(100vh - #{$navbar-height});
  width: $breakpoint-large * 0.45;
  transition: 0.1s $ease-in-out;
  background-color: white;
  box-shadow:
    0 0 24px 0 rgba(136, 152, 170, 0.08),
    7px 0 7px -9px rgba(136, 152, 170, 0.3);

  &.fixed-list {
    top: $navbar-height;
  }
}

.fixed-sidenav-content {
  padding-left: $breakpoint-large * 0.45;
  min-height: calc(100vh - #{$navbar-height} - 40px);
}

.fixed-sidenav-select {
  display: none !important;
}
@media (max-width: $breakpoint-large) {
  body .fixed-sidenav {
    width: $breakpoint-medium * 0.45;
  }
  body .fixed-sidenav-content {
    padding-left: $breakpoint-medium * 0.45;
  }
}
@media (max-width: $breakpoint-medium) {
  body .fixed-sidenav-select {
    display: block !important;
  }
  body .fixed-sidenav {
    display: none !important;
  }
  body .fixed-sidenav-content {
    padding-left: 0;
  }
}
.fixed-sidenav-item {
  @apply leading-tight cursor-pointer;
  color: $grey-110;
  transition: background-color 0.2s $ease-in-out;
  &.active {
    @apply shadow-inset-left shadow-talent-blue;
  }

  &.active,
  &:hover {
    font-weight: 600;
  }
}

// Toastr
.toast-bottom-right {
  width: 100%;
  bottom: 12px !important;
}

.download-text {
  color: white;
  font-size: 18px;
}

.success-text {
  color: #6dc967;
  font-size: 18px;
}

.error-text {
  color: #e74f31;
  font-size: 18px;
}

.info-text {
  color: #2b627b;
  font-size: 18px;
}

// Hubspot
#hubspot-messages-iframe-container {
  z-index: 999998 !important;
}

//scrollbar
//Not supported in Firefox and IE */

/* total width */
.custom-scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.custom-scrollbar::-webkit-scrollbar-track:hover {
  background-color: #e9ecef;
}

/* scrollbar itself */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #f1f1f1;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 3px solid #e9ecef;
}

/* set button(top and bottom of the scrollbar) */
.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

.full-height-container {
  min-height: 500px;
  max-height: 1080px;
  height: calc(100vh - #{$navbar-height} - 40px);
  overflow: hidden;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: rgba(43, 98, 123, 1) !important;
}
