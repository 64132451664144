@use '@angular/material' as mat;
@include mat.core();
@import url('./assets/styles/normalize.css');

@import 'variables';
@import '~ngx-toastr/toastr';

@import 'global-styles';
@import 'design-system';

@import 'variables';

@import 'gridlex/gridlex-vars';
@import 'gridlex/gridlex-preprocessing';
@import 'gridlex/gridlex-mixins';
@import 'gridlex/gridlex-classes';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  line-height: 1.15;
  font-family: 'Lato', sans-serif !important;
  font-weight: 400;
  font-style: normal;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

small {
  @apply text-xs leading-chillax text-grey-8;
}

body {
  background-color: rgba(246, 246, 246, 1);
}

$global-theme-secondary: mat.define-palette(mat.$blue-grey-palette);
$global-theme-warn: mat.define-palette(mat.$deep-orange-palette);

// ---------
// TALENT
// ---------
$talent-theme-primary: mat.define-palette(mat.$deep-orange-palette);
$talent-theme: mat.define-light-theme(
  $talent-theme-primary,
  $global-theme-secondary,
  $global-theme-warn
);
// .talent-theme {
//   @include mat.all-component-themes($talent-theme);
// }

@include mat.all-component-themes($talent-theme);

$custom-typography: mat.define-typography-config(
  $font-family: 'Lato',
  $headline: mat.define-typography-level(32px, 48px, 700),
  $body-1: mat.define-typography-level(14px, 24px, 500),
);

@include mat.all-component-typographies($custom-typography);

// ---------
// SIDENAV
// ---------
.mat-drawer-container,
.mat-sidenav-container {
  background-color: transparent;
  color: unset;
  min-height: calc(100vh - 52px);
  overflow: hidden;
}
.mat-sidenav-content {
  overflow-y: auto;
  overflow-x: hidden;
}
.mat-sidenav {
  width: 400px;
  max-width: 100%;
}

// ---------
// DIALOGS
// ---------
.cdk-overlay-pane {
  max-width: 95vw;
}
.no-padding .mat-dialog-container {
  padding: 0;
}

.mat-dialog-container {
  border-radius: 8px !important;
}
.no-title .mat-dialog-container {
  padding-top: 0;
}
.mat-dialog-actions {
  box-sizing: content-box;
  @apply border-t border-grey-3;
  flex-wrap: nowrap !important;
}
.mat-dialog-title {
  @apply text-2xl font-semibold;
}

// ---------
// PAGINATOR
// ---------

.mat-paginator {
  @apply rounded-lg;
}

// ---------
// INPUTS
// ---------
.mat-form-field {
  line-height: 1rem;
}

// ---------
// SELECT
// ---------
.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    padding: 11.5px 0 1em 0;
    border-top: 10px solid transparent;
  }
  .mat-select-arrow-wrapper {
    transform: none !important;
  }
  .mat-form-field-subscript-wrapper {
    @apply mt-5;
  }
  .mat-form-field-wrapper {
    margin: 0 !important;
    padding-bottom: 0;
  }
  // box color
  .mat-form-field-outline-thick,
  &:not(.mat-form-field-disabled):not(.mat-focused):not(.mat-form-field-invalid)
    .mat-form-field-flex:hover
    .mat-form-field-outline-thick {
    @apply text-grey-4;
  }

  // prefix vertical align
  .mat-form-field-prefix,
  .mat-form-field-suffix {
    align-self: center;
  }
}

.mat-select-panel .mat-optgroup .mat-optgroup-label {
  @apply text-xxs font-semibold text-grey-6 leading-relaxed mt-2.5;
  letter-spacing: 0.5px;
  height: auto;
  text-transform: uppercase;
}

// ---------
// BUTTONS
// ---------
button {
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

button.mat-button-base {
  min-height: 36px;
}

button.mat-button-base.big {
  @apply text-2xl;
  line-height: 60px;
  min-height: 60px;
  @apply pl-6;
  @apply pr-6;
  @apply rounded-lg;
}

button.mat-button-base.small {
  line-height: 30px;
  min-height: 30px;
  @apply font-semibold text-s;
  @apply pl-2.5;
  @apply pr-2.5;
  i {
    @apply text-xl;
  }
}

.mat-flat-button.mat-warn:not([disabled]) {
  background: map-get(mat.$deep-orange-palette, 50);
  color: map-get(mat.$deep-orange-palette, 700);
  i,
  svg {
    color: map-get(mat.$deep-orange-palette, 200);
  }
  .mat-ripple-element {
    background-color: rgba(map-get(mat.$deep-orange-palette, 500), 0.1);
  }
  .mat-button-focus-overlay {
    background-color: map-get(mat.$deep-orange-palette, 600);
  }
}
.mat-flat-button.mat-accent:not([disabled]) {
  background: map-get(mat.$blue-grey-palette, 50);
  color: map-get(mat.$blue-grey-palette, 700);
  i,
  svg {
    color: map-get(mat.$blue-grey-palette, 200);
  }
  .mat-ripple-element {
    background-color: rgba(map-get(mat.$blue-grey-palette, 500), 0.1);
  }
  .mat-button-focus-overlay {
    background-color: map-get(mat.$blue-grey-palette, 600);
  }
}
.mat-button.mat-warn:not([disabled]) {
  i,
  svg {
    color: map-get(mat.$deep-orange-palette, 300);
  }
}
.mat-button.mat-accent:not([disabled]) {
  i,
  svg {
    color: map-get(mat.$blue-grey-palette, 300);
  }
}

.mat-raised-button.mat-primary {
  &:not([class*='mat-elevation-z']):not([disabled]) {
    @apply shadow-sm;
    &:active:not([disabled]) {
      @apply shadow-md;
    }
  }

  i,
  svg-icon {
    opacity: 0.9;
  }
}

.mat-button.mat-primary {
  color: #d93914;
}

.mat-button.mat-secondary {
  color: $blue-60;

  &:hover {
    background: $blue-10 !important;
  }
}

button.pill {
  @apply rounded-full;
}

.inline-button.small {
  @apply font-semibold text-s;
}

// ---------
// SLIDE TOGGLE
// ---------
.mat-slide-toggle:not(.mat-disabled).cdk-focused
  .mat-slide-toggle-persistent-ripple {
  opacity: 0 !important;
}

// ---------
// TOOLTIP
// ---------
.tooltip-no-ellipsis {
  word-break: break-all !important;
  white-space: normal !important;
}

.mat-tooltip {
  background-color: #212121;
  font-size: 14px !important;
  border-radius: 8px !important;
}

.mat-tooltip-show {
  animation: mat-tooltip-show 400ms cubic-bezier(0, 0, 0.2, 1) forwards !important;
}
// ---------
// MAT-MENU
// ---------
.mat-menu-panel.popover-menu {
  &.max-height-300 {
    max-height: 300px;
  }
  &.max-width-350 {
    max-width: 350px;
  }
  &.max-width-280 {
    max-width: 280px;
  }
  &.width-300 {
    width: 300px;
  }
}

.workspace-menu {
  left: 272px;
  top: -50px;
}

.mat-menu-item {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  i {
    vertical-align: text-bottom;
    color: $grey-90;
  }
  svg-icon {
    color: $grey-90;
  }
  color: $grey-90 !important;

  &:hover {
    background-color: $grey-10;
  }
}

.mat-menu-panel.notifications-menu {
  width: 30rem;
  max-width: calc(100vw - 40px);
}

.mat-menu-panel.popover-menu {
  max-width: calc(100vw - 40px);
  &.width-585 {
    width: 585px;
  }
}

.popover-menu .mat-menu-content:not(:empty),
.notifications-menu .mat-menu-content:not(:empty) {
  padding: 0;
}

// ---------
// TEXTAREA
// ---------
textarea.mat-input-element {
  box-sizing: content-box;
}

.mat-form-field-required-marker {
  display: none;
}

mat-checkbox.block .mat-checkbox-layout {
  display: flex;
  .mat-checkbox-label {
    flex-grow: 1;
    white-space: initial;
  }
}

.mat-sort-header-arrow {
  color: #2b627b !important;
}

.mat-header-cell,
.mat-sort-header-content {
  font-weight: 700 !important;
  color: #050505;
  font-size: 14px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #2b627b !important;
}

.mat-radio-outer-circle,
.mat-checkbox-frame {
  border-color: #2b627b !important;
}

$modules: (
  survey: mat.$light-blue-palette,
  admin: mat.$teal-palette,
  pulse: mat.$light-blue-palette,
  push: mat.$indigo-palette,
  feedback: mat.$pink-palette,
  meeting: mat.$pink-palette,
  mybleexo: mat.$cyan-palette,
  warn: mat.$deep-orange-palette,
  accent: mat.$blue-grey-palette,
);

@each $module, $color in $modules {
  .#{$module}-theme {
    .mat-dialog-container {
      @apply text-grey-10;
    }
    .mat-drawer-container,
    .mat-sidenav-container {
      @apply text-grey-10 bg-grey-bg;
    }
    .mat-flat-button.mat-primary:not([disabled]) {
      background-color: rgba(253, 245, 243, 1);
      color: $orange-60;
      i,
      svg {
        color: $orange-60;
      }
      .mat-ripple-element {
        background-color: rgba(map-get($color, 500), 0.1);
      }
      .mat-button-focus-overlay {
        background-color: map-get($color, 600);
      }
    }
    .mat-button.mat-primary:not([disabled]) {
      i,
      svg {
        color: rgb(217, 57, 20);
      }
    }

    .inline-button {
      height: 36px;
      padding: 0px 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: fit-content;
      &:hover {
        background-color: rgba(43, 98, 123, 0.1);
        border-radius: 4px;
      }

      cursor: pointer;
      color: rgba(43, 98, 123, 1);
      transition: 0.05s $ease-in-out;
      font-weight: 600;
      &.bigger-hitbox {
        padding: 10px;
        margin: -10px;
      }
      i,
      svg-icon {
        color: rgba(43, 98, 123, 1);
        @apply mr-2.5;
        transition: 0.05s $ease-in-out;
      }
      i {
        @apply text-xl;
        vertical-align: text-bottom;
      }
      &:hover {
        svg-icon {
          &[icon='arrow-right'] {
            transform: translateX(3px);
          }
        }
      }
    }

    // ---------
    // BUTTON TOGGLE
    // ---------
    .mat-button-toggle-group-appearance-standard,
    .mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
      border-radius: 5px;
    }
    mat-button-toggle {
      &.mat-button-toggle-checked {
        background-color: rgba(map-get($color, 50), 0.5);
      }
      &:not(.mat-button-toggle-checked) {
        @apply text-grey-7;
      }
      .mat-button-toggle-label-content {
        @apply py-0 px-5;
        line-height: 46px;
      }
    }
    .button-toggle-checkbox {
      .mat-button-toggle-label-content {
        @apply text-grey-6;
      }
      svg-icon {
        @apply text-grey-4;
      }
      &.is-checked {
        border-color: map-get($color, 100);
        .mat-button-toggle-label-content {
          color: map-get($color, 600);
        }
        svg-icon {
          color: map-get($color, 300);
        }
      }
      &.mat-button-toggle-disabled {
        .mat-button-toggle-label-content {
          @apply text-grey-5;
        }
        svg-icon {
          @apply text-grey-7;
        }
      }
    }

    @media (max-width: $breakpoint-extra-small) {
      .mat-button-toggle-group {
        flex-direction: column;
      }
      .mat-button-toggle-group .mat-button-toggle + .mat-button-toggle {
        border-left: none;
        border-right: none;
        border-top: solid 1px rgba(0, 0, 0, 0.12);
      }
    }

    .mat-button-toggle-group.boolean-marks {
      mat-button-toggle {
        &.mat-button-toggle-checked {
          &:first-child {
            @apply bg-red-2 text-red-7;
          }
          &:last-child {
            @apply text-green-7 bg-green-2;
          }
          &.disabled {
            @apply bg-black/[.14];
            color: rgba(176, 176, 176, 1) !important;
            @apply border-black/[.14];
          }
        }
        &.disabled {
          @apply border-black/[.14];
          color: rgba(176, 176, 176, 1) !important;
        }
        .mat-button-toggle-label-content {
          @apply py-0 px-2.5;
          line-height: 30px;
        }
      }
    }

    // ---------
    // VERTICAL STEPPER
    // ---------
    .mat-stepper-vertical {
      @apply bg-grey-1;
      .mat-step-label {
        @apply text-base;
      }
    }
    // ---------
    // HORIZONTAL STEPPER
    // ---------
    .mat-stepper-horizontal {
      @apply bg-grey-1;
      .mat-horizontal-content-container {
        display: none;
      }
    }
  }
}

.mat-stepper-horizontal {
  @apply bg-grey-1;
  .mat-horizontal-content-container {
    display: none;
  }
}

.mat-hint {
  color: rgba(5, 5, 5, 1);
}

input,
textarea {
  color: rgba(5, 5, 5, 1);
}

.mat-flat-button.mat-primary,
.mat-step-header .mat-step-icon-selected,
.mat-raised-button.mat-primary {
  background-color: $orange-60;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow,
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
  .mat-form-field-outline-thick {
  color: $orange-60;
}

.mat-error {
  color: $orange-60;
  padding-left: 4px;
  font-style: italic;
}

.mat-step-header .mat-step-icon-state-edit {
  background-color: rgba(46, 125, 50, 1);
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $orange-60;
}

.mat-dialog-container {
  border-radius: 8px;
}

.no-lr-padding {
  .mat-dialog-container {
    padding: 24px 0px 0px 0px;
  }
}

.no-padding {
  .mat-dialog-container {
    padding: 0px;
  }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: rgba(43, 98, 123, 1);
}
.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: rgba(43, 98, 123, 1);
}

.mat-raised-button.mat-primary {
  &.custom-meeting-button {
    background-color: rgba(43, 98, 123, 1) !important;
  }
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: rgba(43, 98, 123, 1) !important;
}
.mat-input-element {
  caret-color: rgba(43, 98, 123, 1) !important;
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: rgba(43, 98, 123, 1) !important;
}

.grey-110 {
  color: $grey-110;
}

.text-note {
  color: $grey-90;
}

.text-header {
  font-weight: 400;
}

.expand-icon {
  color: $grey-110 !important;
}

.expand-card-section-header {
  color: $blue-60;
  font-size: 16px;
}

.text-header,
meeting-section-header {
  color: $grey-110;
}

.lang-select {
  .mat-select-placeholder {
    color: rgba($grey-50, 0.5);
  }

  &.disable {
    .mat-select-arrow {
      color: rgba($grey-50, 0.5) !important;
    }
  }
}

.mat-tab-group.mat-primary
  .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: unset;
}

.mat-table {
  th {
    border-bottom-color: rgba(0, 0, 0, 0.5);
  }
}

.mat-progress-bar {
  &.progress-big {
    height: 16px !important;

    .mat-progress-bar-fill::after {
      border-radius: 5px;
      left: 4px !important;
      width: calc(100% - 8px);
      height: 10px !important;
      border-radius: 12px !important;
      overflow: hidden !important;
    }
  }

  &:not(.pristine) {
    height: 8px !important;
  }
}

.mat-progress-bar-fill {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: none !important;
}

.mat-progress-bar-buffer {
  background-color: #f6f6f6;
  border-radius: 16px;
  padding: 2px;
}

.low-progress {
  .mat-progress-bar-fill::after {
    background-color: #d93914;
  }
}

.middle-progress {
  .mat-progress-bar-fill::after {
    background-color: #ffb800;
  }
}

.high-progress {
  .mat-progress-bar-fill::after {
    background-color: #3a9634;
  }
}

.indeterminate-progress {
  .mat-progress-bar-fill::after {
    background-color: #2b627b;
  }
}

.mat-progress-bar-fill::after {
  border-radius: 5px;
  left: 4px !important;
  width: calc(100% - 8px);
  height: 4px !important;
  border-radius: 12px !important;
  overflow: hidden !important;
}

.mat-progress-bar-background {
  fill: none !important;
}

.h-8-5 {
  height: 35px;
}

.mat-drawer-backdrop.mat-drawer-shown,
.cdk-overlay-dark-backdrop {
  background: #33333380 !important;
  backdrop-filter: blur(4px);
}

.status-pill {
  border-radius: 24px;
  padding: 4px 16px 4px 12px;
  margin: 0px 8px 0px 0px;
  white-space: nowrap;
  background-color: #f6f6f6;
  color: #050505;

  &::before {
    padding-right: 4px;
    content: '• ';
    font-size: larger;
    color: #828282;
  }

  &.green {
    background-color: #ebf4eb;
    &::before {
      color: #3a9634;
    }
  }
  &.red {
    background-color: #fef0e6;
    &::before {
      color: #f76707;
    }
  }
}

markdown-editor {
  position: relative;
  .label {
    @-moz-document url-prefix() {
      top: 24px !important;
    }

    padding: 0px !important;
    background-color: white;
    position: absolute;
    top: 12px;
    left: 12px;
    transition: all 200ms;
    font-size: 14px;
    color: #828282;
    width: max-content;

    &:not(.readOnly) {
      &.has-value {
        @-moz-document url-prefix() {
          top: 2px !important;
        }
        top: -12px;
        left: 6px;
        transition: all 200ms;
        font-size: smaller;
        padding: 0px 6px !important;
      }

      &.focused {
        padding: 0px 6px !important;
        color: #2b627b;

        @-moz-document url-prefix() {
          top: 2px !important;
        }

        top: -12px;
        left: 6px;
        transition: all 200ms;
        font-size: smaller;
      }

      &.required:after {
        content: ' *';
        color: #d93914;
      }

      &.error {
        &:not(.focused) {
          color: #d93914;
        }
      }
    }
  }
}

.markdown-editor-container {
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  .ql-editor {
    max-height: 300px !important;
  }

  span[style*='background-color: lightgrey'] {
    padding: 4px 0px !important;
  }

  box-shadow:
    -1px -1px 0px 0px #e5e7eb,
    1px 1px 0px 0px #e5e7eb;

  &:not(.readOnly) {
    &.error {
      &:not(.focused) {
        box-shadow:
          -1px -1px 0px 1px #d93914,
          1px 1px 0px 1px #d93914;
        transition: all 50ms;
        color: #d93914;
      }
    }

    &.focused {
      box-shadow:
        -1px -1px 0px 1px #2b627b,
        1px 1px 0px 1px #2b627b;
      transition: all 50ms;
    }

    &.hover {
      &:not(.focused):not(.error) {
        box-shadow:
          -1px -1px 0px 1px rgb(206, 212, 218),
          1px 1px 0px 1px rgb(206, 212, 218);
        transition: all 200ms;
      }
    }
  }

  .ql-toolbar {
    border: 0px !important;
    border-top: 1px solid #e5e7eb !important;
  }

  .ql-container.ql-snow {
    border: 0px !important;
  }

  .ql-tooltip {
    // right: 32px !important;
    top: unset !important;
    bottom: -32px !important;
    left: 158px !important;
    padding: 8px !important;
    background-color: unset !important;
  }

  .ql-snow .ql-tooltip[data-mode='link'] {
    border-radius: 8px;
    width: calc(100% - 188px) !important;

    input {
      border-radius: 5px;
      width: 100%;
    }
  }
}

.markdown-editor-container .ql-tooltip {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.ql-snow .ql-tooltip[data-mode='link']::before {
  content: none;
}

.ql-snow .ql-tooltip:not([data-mode='link']) a.ql-action::after {
  content: '✎';
  display: inline-block;
  transform: scaleX(-1);
  font-size: larger;
  border-right: none !important;
  border-left: 1px solid #ccc;
  padding-right: 0px !important;
  padding-left: 8px;
}

.ql-snow a {
  color: #2b627b;
}

.ql-snow .ql-tooltip::before {
  content: 'URL:';
}

.ql-snow .ql-tooltip a.ql-remove::before {
  content: '✕';
}

.ql-snow .ql-tooltip[data-mode='link'] {
  border: 0px !important;
  box-shadow: none !important;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: 'OK';
  margin-left: 8px !important;
  color: #2b627b;
}

.word-break {
  word-break: break-word;
}

.markdown-format {
  word-break: break-word;
  a {
    color: #2b627b;
    text-decoration: revert;
  }

  ul {
    margin: revert;
    padding: revert;
  }
}
