@import 'variables';
// **************
// CLASSES
// **************

// ---------
// POSITIONS
// ---------
.p-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// **************
// GRIDLEX PRIORITY IMPORTANT
// **************
@media (max-width: $breakpoint-extra-small) {
  [class*='xs-hidden'] {
    display: none !important;
  }
}

@media (max-width: $breakpoint-small) {
  [class*='sm-hidden'] {
    display: none !important;
  }
}

@media (max-width: $breakpoint-medium) {
  [class*='md-hidden'] {
    display: none !important;
  }
}
